import React from 'react';
import Footer from './Footer';
import axios from 'axios';
import { object, string, number } from 'yup';

function Register() {
  let initialState = {
    name: '',
    email: '',
    phone: '',
    courses: '--',
  };

  const [formData, setFormData] = React.useState(initialState);
  const [showAlert, setShowAlert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  let schema = object().shape({
    name: string().required(),
    email: string().email().required(),
    phone: number().required(),
    courses: string()
      .test('is-valid', 'Please select a course', (value) => value !== '--')
      .required(),
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setFormData(initialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(formData);
    } catch (error) {
      return alert(error.message);
    }
    setIsLoading(true);
    await axios
      .post('https://appendgooglesheet-rao5lvirzq-uc.a.run.app', formData)
      .then(() => {
        setShowAlert(true);
        handleReset();
        setIsLoading(false);
      });
  };

  return (
    <div style={{ margin: '190px 0px 0px 0px' }}>
      <div className="row align-items-start" id="contactMe">
        <div
          style={{ padding: '0 60px 0 0' }}
          className="col-sm-6"
          id="contactText"
        >
          <h1 className="text-info" style={{ fontSize: '44px' }}>
            Test Automation Mastery Course
          </h1>
          <h2 className={'text-auto'} style={{ fontSize: '24px' }}>
            Course Objectives
          </h2>
          <p style={{ fontSize: '15px' }}>
            The Test Automation Mastery Course aims to empower learners with the
            comprehensive knowledge and hands-on skills in modern test
            automation techniques. By mastering industry-leading tools like
            Playwright with TypeScript for UI testing and Rest Assured with Java
            for API testing, our goal is to equip participants with the
            expertise to build robust, scalable, and efficient automated testing
            solutions. We strive to cultivate a deep understanding of best
            practices, integration strategies, and project-oriented approaches
            to prepare students for real-world challenges in test automation.
          </p>
          <h2 className={'text-auto'} style={{ fontSize: '24px' }}>
            Who will benefit?
          </h2>
          <p style={{ fontSize: '15px' }}>
            The Test Automation Mastery Course is designed for software
            developers, testers, and quality assurance professionals who are
            looking to enhance their skills in test automation. Whether you are
            a beginner or an experienced professional, this course will provide
            you with the knowledge and tools to excel in test automation. By the
            end of the course, you will be equipped with the expertise to
            design, implement, and maintain automated testing solutions that
            meet the highest standards of quality and efficiency.
          </p>
          <h2 className={'text-auto'} style={{ fontSize: '24px' }}>
            Prerequisites
          </h2>
          <p style={{ fontSize: '15px' }}>
            Basic knowledge of computers and a willingness to learn programming.
            No prior programming experience required.
          </p>
          <h2 className={'text-auto'} style={{ fontSize: '24px' }}>
            Skills Gained
          </h2>
          <ul className={'list-group'}>
            <li className={'list-group-item'} style={{ fontSize: '15px' }}>
              Comprehensive understanding of test automation concepts and
              techniques
            </li>
            <li className={'list-group-item'} style={{ fontSize: '15px' }}>
              Proficiency in Playwright with TypeScript for UI testing
            </li>
            <li className={'list-group-item'} style={{ fontSize: '15px' }}>
              Expertise in Rest Assured with Java for API testing
            </li>
            <li className={'list-group-item'} style={{ fontSize: '15px' }}>
              Hands-on experience in building automated testing solutions
            </li>
            <li className={'list-group-item'} style={{ fontSize: '15px' }}>
              Knowledge of best practices and strategies in test automation
            </li>
            <li className={'list-group-item'} style={{ fontSize: '15px' }}>
              Project-oriented approach to test automation
            </li>
          </ul>
        </div>
        <div className="col-sm-6">
          {showAlert && (
            <div className="alert alert-success" role="alert">
              Registration Successful, we will contact you soon!
            </div>
          )}
          <form className="mt-4" method="POST" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                required
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                required
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Number" className="form-label">
                Phone Number
              </label>
              <input
                className="form-control"
                id="Number"
                name="phone"
                value={formData.phone}
                required
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={'courses'} className={'form-label'}>
                Course Start Date
              </label>
              <select
                className="form-select"
                id="courses"
                name="courses"
                value={formData.courses}
                required
                onChange={handleChange}
              >
                <option value="--" disabled>
                  --
                </option>
                <option value="Test Automation Mastery Course November 9th 2024">
                  November 9th 2024
                </option>
                <option
                  value={'Test Automation Mastery Course January 11th 2025'}
                >
                  January 11th 2025
                </option>
                <option
                  value={'Test Automation Mastery Course March 15th 2025'}
                >
                  March 15th 2025
                </option>
                <option value={'Test Automation Mastery Course May 10th 2025'}>
                  May 10th 2025
                </option>
                <option value={'Test Automation Mastery Course July 5th 2025'}>
                  July 5th 2025
                </option>
                <option
                  value={'Test Automation Mastery Course September 6th 2025'}
                >
                  September 6th 2025
                </option>
              </select>
            </div>
            <button type="submit" className="btn btn-info" disabled={isLoading}>
              Register
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
