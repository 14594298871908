import React from 'react';
import Privacy from '../component/Privacy';

function PrivacyData() {
  return (
    <div className="privacydata">
      <Privacy />
    </div>
  );
}

export default PrivacyData;
