import React from 'react';
import Register from '../component/Register';

function Training() {
  return (
    <div id="myContactus">
      <Register />
    </div>
  );
}

export default Training;
