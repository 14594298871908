import React from 'react';
import About from '../component/About';
import Footer from '../component/Footer';

function AboutUs() {
  return (
    <div style={{ margin: '140px 0px 0px' }} id="myAbout">
      <About />
      <div id="myFooter">
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
