import React from 'react';
import { useRef } from 'react';
import Footer from './Footer';
import sendEmail from '../utilities/email';

function Contactus() {
  const [btn_text, setBtnText] = React.useState('Send Message');
  const [message, setMessage] = React.useState('');
  const form = useRef();

  const handleContact = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setBtnText('Sending...');
      const result = sendEmail(form);
      result.then(
        () => {
          setMessage('Your message has been sent. Thank you!');
          setBtnText('Send Message');
          e.target.reset();
        },
        (error) => {
          setMessage(
            `Failed to send message. Error: ${JSON.stringify(error, null, 2)}`,
          );
          setBtnText('Send Message');
        },
      );
    }, 2000);
  };
  return (
    <div style={{ margin: '190px 0px 0px 0px' }}>
      <div className="row align-items-start" id="contactMe">
        <div
          style={{ padding: '0 60px 0 0' }}
          className="col-sm-6"
          id="contactText"
        >
          <h1 className="text-info" style={{ fontSize: '44px' }}>
            Our Contact
          </h1>
          <p style={{ fontSize: '15px' }}>
            As a software development company, we are dedicated to delivering
            innovative and high-quality software solutions to our clients. We
            are passionate about using technology to solve complex problems and
            make a positive impact on people's lives.If you would like to learn
            more about our services or discuss your project with us, please do
            not hesitate to contact us. Our team of experts is always happy to
            answer any questions you may have and provide you with a free
            consultation. We look forward to working with you to deliver
            innovative and high-quality software solutions that drive your
            business forward.
          </p>
          <h2 style={{ fontSize: '15px' }}>
            Tell us about your project at info@lagcloud-solutions or with the
            contact form below.All submitted information will be kept
            confidential
          </h2>
        </div>
        <div className="col-sm-6">
          <form
            ref={form}
            className="mt-4"
            onSubmit={handleContact}
            method="POST"
          >
            <div className="mb-3">
              <label for="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="user_name"
                required
              />
            </div>
            <div class="mb-3">
              <label for="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="user_email"
                required
              />
            </div>
            <div class="mb-3">
              <label for="Number" className="form-label">
                Phone Number
              </label>
              <input
                type="Number"
                className="form-control"
                id="Number"
                name="phone"
                required
              />
            </div>
            <div class="mb-3">
              <label for="Company's Name" className="form-label">
                Company's Name
              </label>
              <input
                type="Company's Name"
                className="form-control"
                name="company"
                required
              />
            </div>
            <div class="mb-3">
              <label for="message" className="form-label">
                Message
              </label>
              <textarea
                class="form-control"
                id="message"
                name="message"
                rows="4"
                required
              ></textarea>
            </div>
            {message && (
              <div className="alert alert-success" role="alert">
                {message}
              </div>
            )}
            <button type="submit" className="btn btn-info">
              {btn_text}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contactus;
