import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <nav className="navbar navbar-expand-md fixed-top border-bottom border-info bg-white">
        <div className="container-fluid">
          <Link to="/">
            <img
              src="static/images/lagcloud-removebg-preview.png"
              className="lg-logo"
              alt="lag-cloud logo"
            />
          </Link>
          <button
            className="navbar-toggler text-info"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExampleLabel"
          >
            Menu
          </button>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExample"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                <img
                  src="static/images/lagcloud-removebg-preview.png"
                  alt="logo"
                />
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item ">
                  <Link
                    className="nav-link active px-3 text-dark"
                    aria-current="page"
                    to="/"
                  >
                    <button
                      role="button"
                      className="btn-transparent"
                      data-bs-dismiss="offcanvas"
                    >
                      Home
                    </button>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active px-3 text-dark"
                    to="/aboutus"
                  >
                    <button
                      role="button"
                      className="btn-transparent"
                      data-bs-dismiss="offcanvas"
                    >
                      About us
                    </button>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active px-3 text-dark"
                    to="/services"
                  >
                    <button
                      role="button"
                      className="btn-transparent"
                      data-bs-dismiss="offcanvas"
                    >
                      Our Services
                    </button>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active px-3 text-dark"
                    to="/training"
                  >
                    <button
                      role="button"
                      className="btn-transparent"
                      data-bs-dismiss="offcanvas"
                    >
                      Training
                    </button>
                  </Link>
                </li>
              </ul>
              <form className="d-flex">
                <Link to="/contact">
                  <button
                    type="button"
                    className="btn btn-info"
                    data-bs-dismiss="offcanvas"
                  >
                    Contact us
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
