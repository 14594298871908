import React from 'react';
import Contactus from '../component/Contactus';

function Contact() {
  return (
    <div id="myContactus">
      <Contactus />
    </div>
  );
}

export default Contact;
