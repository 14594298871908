import React from 'react';
import Footer from './Footer';

function Terms() {
  return (
    <div>
      <div className="terms">
        <h4 className="mb-2 text-center fs-2">
          Lag Cloud Terms and Condition{' '}
        </h4>
        <h6 className="mb-5 text-center">
          Please read all terms and conditions
        </h6>
        <p>
          Welcome to lagcloud. By accessing our website, you agree to these
          Terms and Conditions of Use ("Terms''). Please read them carefully.
          These Terms govern your use of lagcloud and any related services
          provided by lagcloud.
        </p>
        <h4 className="mt-5 mb-4 ">Privacy and Data Protection</h4>
        <p>
          Your privacy is critically important to us. Our Privacy Policy,
          compliant with the General Data Protection Regulation (GDPR), outlines
          how we collect, use, store, and protect your personal data. By using
          our website, you consent to the processing of your personal data as
          described in our Privacy Policy.
        </p>

        <h4 className="mt-5 mb-4 ">Intellectual Property Rights</h4>
        <p>
          The content on our website, including text, graphics, logos, and
          images, is owned by lagcloud or our content suppliers and is protected
          by copyright and intellectual property laws. You may not use our
          content without express written consent.
        </p>

        <h4 className="mt-5 mb-4 ">Website Use</h4>
        <p>
          lagcloud grants you a limited licence to access and make personal use
          of our website. This licence does not include any resale or commercial
          use of our website or its content.
        </p>
        <h4 className="mt-5 mb-4 ">User Conduct</h4>
        <p>
          You agree to use our website only for lawful purposes. You are
          prohibited from using our website following below: In any way that
          breaches any applicable local, national, or international law or
          regulation. To send, knowingly receive, upload, download, use, or
          re-use any material which does not comply with our content standards.
          To transmit, or procure the sending of, any unsolicited or
          unauthorised advertising or promotional material.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
