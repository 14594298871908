import React from 'react';
import myData from '../myData';
import Card from './Card';

function Marketing() {
  return (
    <section className=" services-section container-fluid " id="marketingbox">
      <h1 className="services-header py-4">Services we offer</h1>
      <div className="container">
        <div className="row">
          {myData.map((item, i) => {
            return <Card title={item.title} body={item.body} key={i} />;
          })}
        </div>
      </div>
    </section>
  );
}

export default Marketing;
