const myData = [
  {
    title: 'Web Design & Development',
    body: 'From stunning user interfaces to powerful back-end functionality, we excel at delivering web applications that captivate and perform.',
  },
  {
    title: 'Testing & Quality Assurance',
    body: 'Our testing and quality assurance services ensure that your software meets the highest standards of quality, performance, and security.',
  },
  {
    title: 'Training',
    body: 'We offer a range of training programs designed to help you master the latest technologies and tools in software development.',
  },
];

export default myData;
