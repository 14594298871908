import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './component/Header';
import Home from './page/Home';
import Services from './page/Services';
import AboutUs from './page/AboutUs';
import Contact from './page/Contact';
import PrivacyData from './page/PrivacyData';
import MyTerms from './page/MyTerms';
import Training from './page/Training';

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/services" component={Services} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy" component={PrivacyData} />
          <Route path="/Terms" component={MyTerms} />
          <Route path="/Training" component={Training} />
        </Switch>
      </Router>
    </div>
  );
}
export default App;
